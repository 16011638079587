<template>

<app-screen :menu="true" :loading="is.loading" title="Leaderboard" toolbar="leaderboard">

	<div v-for="(team, index) in teams" :key="team.id" class="leaderboard-item" :class="{'is-own': team.id === ownTeam}">

		<div class="leaderboard-item-name">{{ team.name }}</div>
		<div class="leaderboard-item-score">Avg. score: {{ team.average | round }}</div>
		<div class="leaderboard-item-participation">Participation: {{ team.participation | round }}%</div>
		<div class="leaderboard-item-position">{{ index + 1 | nth }}</div>

	</div>
	
</app-screen>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				loading: true
			},
			teams: []
		}

	},

	created: function() {

		this.refresh()

	},

	computed: {

		ownTeam: function() {

			return this.$store.getters['session'].team

		}

	},

	methods: {

		refresh: function() {

			this.is.loading = true

			this.$api.request(this.$constants.api.leaderboard).then(function(response) {

				this.teams = response.teams

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.leaderboard-item {
	padding: 20px 64px 20px 20px;
	color: #1D81D6;
}

.leaderboard-item.is-own {
	background-color: #1D81D6;
	color: #fff;
}

.leaderboard-item-name {
	font-weight: bold;
	font-size: 16px;
	text-transform: uppercase;
	margin-bottom: 20px;
}

.leaderboard-item-score {
	text-transform: uppercase;
	font-size: 16px;
	margin-bottom: 4px;
}

.leaderboard-item-participation {
	text-transform: uppercase;
	font-size: 16px;
}

.leaderboard-item-position {
	width: 40px;
	height: 40px;
	position: absolute;
	right: 20px;
	top: 50%;
	margin-top: -20px;
	border-radius: 20px;
	text-align: center;
	font-size: 16px;
	font-weight: bold;
	line-height: 40px;
	background-color: #1D81D6;
	color: #fff;
}

.leaderboard-item.is-own .leaderboard-item-position {
	background-color: #fff;
	color: #1D81D6;
}


</style>
