<template>

<app-screen title="Tasks" toolbar="tasks" :menu="true">

	<com-summary />
	<com-pending />
	<com-completed />
	
</app-screen>

</template>

<script>

import comSummary from './tasks/Summary'
import comPending from './tasks/Pending'
import comCompleted from './tasks/Completed'

export default {

	components: {
		'com-summary': comSummary,
		'com-pending': comPending,
		'com-completed': comCompleted
	}

}

</script>

<style scoped>

</style>
