import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import _ from 'underscore'
import constants from '../constants'

Vue.use(Vuex)

export default new Vuex.Store({

	state: {
		
		time: {
			current: moment().utc().unix(),
			offset: 0
		},

		data: {
			teams: [],
			tasks: [],
			settings: false,
			session: false
		}

	},

	getters: {
		
		time: function(state) {

			return state.time.current + state.time.offset
			
		},

		'time/current': function(state) {

			return state.time.current + state.time.offset

		},

		'time/offset': function(state) {

			return state.time.offset

		},

		'time/raw': function(state) {

			return state.time.current

		},

		teams: function(state) {

			return state.data.teams

		},

		tasks: function(state) {

			return state.data.tasks

		},

		session: function(state) {

			return state.data.session

		},

		settings: function(state) {

			return state.data.settings

		},

		'session/tasks': function(state) {

			var tasks = []

			_.each(state.data.session.tasks, function(task) {

				tasks[task.id] = task

			})

			return tasks

		},

		'session/tasks/completed': function(state) {

			if (state.data.session) {

				return _.pluck(_.where(state.data.session.tasks, {
					status: constants.task.complete
				}), 'id')

			} else {

				return []

			}

		}

	},

	mutations: {

		'time/current': function(state, value) {

			state.time.current = value

		},

		'time/offset': function(state, value) {

			state.time.offset = value

		},

		teams: function(state, value) {

			state.data.teams = value

		},

		tasks: function(state, value) {

			state.data.tasks = value

		},

		session: function(state, value) {

			state.data.session = value

		},

		'session/permit/feed': function(state, value) {

			state.data.session.permit.feed = value

		},

		settings: function(state, value) {

			state.data.settings = value

		}

	},

	actions: {

	}

})
