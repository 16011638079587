<template>

<div class="text" :class="{'is-loading': loading}">
	
	<input ref="input" :tabindex="tabindex" :disabled="locked" :type="inputType" class="text-input" :placeholder="placeholder" :value="value" v-on:keyup="onChange" v-on:change="onChange" />

	<i class="fa fa-check text-validated" v-if="validated"></i>
	
</div>

</template>

<script>

export default {

	props: ['placeholder', 'tabindex', 'locked', 'value', 'email', 'validated', 'loading'],

	computed: {

		inputType: function() {

			return (this.email) ? 'email' : 'text'

		}

	},

	methods: {

		onChange: function(e) {

			this.$emit('change', e.target.value)

		}

	}

}

</script>

<style scoped>

.text {
	height: 48px;
	background-color: #fff;
}

.text.is-loading {
	background-image: url('data:image/gif;base64,R0lGODlhEAALAPQAAP///yaZ+9/v/dbr/ez2/Sub+yaZ+02r+5TM/He//MTi/UKm+2S2+5vP/HvA/Mfk/Uao+yma+2i4++n0/d3u/fX6/VWv++Hw/fP5/cDh/a7Y/NHp/fD3/QAAAAAAAAAAACH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAALAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQACwABACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQACwACACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQACwADACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAALAAQALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkEAAsABQAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAALAAYALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkEAAsABwAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA');
	background-position: calc(100% - 8px) 50%;
	background-repeat: no-repeat;
}

.text-input {
	width: 100%;
	border: 1px solid #BCE0FD;
	height: 48px;
	font-size: 14px;
	padding: 0px 10px;
	color: #333;
}

.text-input::placeholder {
	color: #315f85;
}

.text-input:focus {
	border-color: #1D81D6;
}

.text-validated {
	position: absolute;
	font-size: 16px;
	right: 10px;
	top: 50%;
	margin-top: -8px;
	color: #1D81D6;
}

</style>