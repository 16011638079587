<template>

<div class="message">

	<div class="message-title" v-html="texts.ob.message.title"></div>
	<div class="message-text" v-html="texts.ob.message.text"></div>
 
	<div class="message-video-wrapper" v-on:click="onPlayClick" :class="{'is-playing': is.playing}">
		<video ref="video" class="message-video" v-on:ended="onEnded" playsinline>
			<source :src="texts.ob.message.video" />
		</video>
		<i class="fa fa-play" v-if="!is.playing"></i>
	</div>
	
</div>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				playing: false
			}
		}

	},

	methods: {

		onPlayClick: function() {

			if (!this.is.playing) {

				this.is.playing = true
				this.$refs.video.play()

			}

		},

		onEnded: function() {

			this.$emit('ended')

		}

	}

}

</script>

<style scoped>

.message {
	width: 100%;
	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: center;
}

.message-title {
	color: #1D81D6;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	width: 100%;
	margin-bottom: 32px;
}

.message-text {
	color: #1D81D6;
	width: 100%;
	text-align: center;
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 32px;
}

.message-video {
	width: 100%;
	height: auto;
	z-index: 1;
	background-color: #000;
}

.message-video-wrapper:after {
	content: ' ';
	cursor: pointer;
	display: block;
	width: 100%;
	height: 100%;
	top: 0px;
	bottom: 0px;
	position: absolute;
	z-index: 2;
	background-color: rgba(0, 0, 0, 0.5);
}

.message-video-wrapper.is-playing:after {
	display: none;
}

.message-video-wrapper .fa {
	z-index: 3;
	color: #fff;
	font-size: 64px;
	position: absolute;
	cursor: pointer;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

</style>
