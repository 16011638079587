<template>

<app-screen :loading="true">

</app-screen>

</template>

<script>

export default {

	created: function() {

		this.$api.request(this.$constants.api.session).then(function(response) {

			this.$store.commit('teams', response.teams)
			this.$store.commit('tasks', response.tasks)
			this.$store.commit('session', response.session)
			this.$store.commit('settings', response.settings)

			if (response.session) {

				this.$router.push({
					name: 'Tasks'
				})

			} else {

				this.$router.push({
					name: 'Login'
				})

			}

		}.bind(this), function() {

			this.$router.push({
				name: 'Login'
			})

		}.bind(this))

	}

}

</script>

<style scoped>

</style>
