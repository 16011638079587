import pubsub from '@/service/pubsub'
import vhcheck from 'vh-check'

function windowResize() {

	var height = window.innerHeight
	var width = window.innerWidth

	window.sizeData = {
		width: width,
		height: height,
		is: {
			mobile: width < 640,
			tablet: width >= 640 && width < 1120,
			desktop: width >= 1120
		}
	}

	vhcheck()

	pubsub.$emit('window.resize', window.sizeData)

}

pubsub.$on('window.initiated', function() {

	vhcheck()
	windowResize()

})

window.addEventListener('resize', windowResize)
window.addEventListener('orientationchange', windowResize)

export default {

	data: function() {
		
		return {
			window: {
				width: 0,
				height: 0,
				is: {
					mobile: false,
					tablet: false,
					desktop: false
				}
			}
		}

	},

	created: function() {

		this.window = window.sizeData

		pubsub.$on('window.resize', this.onWindowResize.bind(this))

	},

	beforeDestroy: function() {

		pubsub.$off('window.resize', this.onWindowResize.bind(this))

	},

	methods: {

		onWindowResize: function(data) {

			this.window = data

		}

	}

}