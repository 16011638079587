<template>

<app-screen :title="texts.ob.message.title" :textOnly="true" :menu="true">

	<p>{{ texts.ob.message.text }}</p>

	<video class="message-video" playsinline controls>
		<source :src="texts.ob.message.video" />
	</video>
	
</app-screen>

</template>

<script>

export default {

}

</script>

<style scoped>

.message-video {
	width: 100%;
    height: auto;
}

</style>
