<template>

<app-screen :centralised="true" background="blue">

	<img class="login-earthday" src="@/assets/logo-earthday-2.png" />

	<div class="login-intro" v-if="!is.newbie">
		
		Hi! What's your email address?

	</div>

	<div class="login-email">

		<app-input ref="email" :locked="is.newbie" :tabindex="1" type="text" :email="true" :loading="is.checking" :validated="is.registered" placeholder="Email" v-model="input.email" />

	</div>

	<div class="login-register" v-if="is.newbie">

		<app-input :tabindex="2" type="text" placeholder="Full Name" v-model="input.name" />

		<app-input :tabindex="3" type="select" placeholder="Department" v-model="input.team" :options="teams" />

	</div>

	<app-button text="Continue" class="login-button" :errored="is.errored" v-on:click="onContinueClick" v-if="!is.newbie" :disabled="!isValidEmail" :loading="is.checking || is.registering" />
	<app-button text="Register" class="login-button" :errored="is.errored" v-on:click="onRegisterClick" v-if="is.newbie" :disabled="!isValidRegistration" :loading="is.checking || is.registering" />

	<p class="login-register-legal" v-if="is.newbie">By creating an account you agree to our<br/><router-link :to="{name: 'Terms'}">Terms of Service</router-link> and <router-link :to="{name: 'Privacy'}">Privacy Policy</router-link></p>

</app-screen>

</template>

<script>

export default {

	data: function() {

		return {
			key: '',
			input: {
				email: '',
				name: '',
				team: false
			},
			is: {
				registered: false,
				registering: false,
				checking: false,
				newbie: false,
				errored: false
			},
			re: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		}

	},

	computed: {

		teams: function() {

			var teams = []
			
			this.$_.each(this.$store.getters['teams'], function(team) {

				teams.push({
					value: team.id,
					text: team.name
				})

			})

			return teams

		},

		emailAddress: function() {

			return this.input.email

		},

		isValidEmail: function() {

			var email = this.input.email.toLowerCase()

			if (this.re.test(String(email).toLowerCase())) {

				var whitelist = this.$store.getters['settings'].whitelist

				if (whitelist) {

					return this.$_.find(whitelist, function(domain) {

						return email.indexOf(domain) > 0

					})

				} else {

					return true

				}

			} else {

				return false

			}

		},

		isRegistering: function() {

			return this.isValidEmail && !this.is.registered

		},

		isValidRegistration: function() {

			return this.input.name && this.input.team

		},

		isValid: function() {

			return this.isValidEmail && this.isValidRegistration

		}

	},

	watch: {

		isValidEmail: function(n) {

			if (!n) {

				this.is.registered = false

			}

		}

	},

	methods: {

		onRegisterClick: function() {

			if (this.isRegistering) {

				this.is.registering = true
				this.is.errored = false

				this.$api.request(this.$constants.api.login.register, {
					email: this.input.email,
					name: this.input.name,
					team: this.input.team
				}).then(function(response) {

					this.$router.push({
						name: 'Verify',
						query: {
							user: response.registered
						}
					})

				}.bind(this), function() {

					this.is.registering = false
					this.is.errored = true

				}.bind(this))

			} else {

				this.$router.push({
					name: 'Verify',
					query: {
						user: this.is.registered
					}
				})

			}

		},

		onContinueClick: function() {

			if (this.isValidEmail) {

				this.is.checking = true

				this.$api.request(this.$constants.api.login.check, {
					email: this.input.email
				}).then(function(response) {

					this.is.registered = response.registered
					this.is.checking = false

					if (this.is.registered) {

						this.$router.push({
							name: 'Verify',
							query: {
								user: this.is.registered
							}
						})

					} else {

						this.is.newbie = true

					}

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

.login-earthday {
	width: 263px;
	height: 83px;
	margin-bottom: 10px;
}

.login-intro {
	font-size: 16px;
	line-height: 24px;
	color: #1D81D6;
	width: 100%;
	margin-bottom: 10px;
	text-align: center;
}

.login-email {
	padding: 0px;
	width: 300px;
}

.login-button {
	width: 300px;
	margin-top: 20px;
}

.login-register {
	padding: 8px 0px;
	width: 300px;
	height: 128px;
}

.login-register >>> .input {
	margin: 8px 0px;
}

.login-register-legal {
	font-size: 10px;
	line-height: 20px;
	color: #1D81D6;
	text-align: center;
	margin-top: 20px;
}

</style>
