<template>

<div class="select" :class="{'is-loading': loading, 'is-placeholder': !value}">
	
	<div class="select-value" v-on:click="onToggleExpanded">{{ valueText }} <i class="fa fa-chevron-down"></i></div>

	<div class="select-options" v-if="is.expanded">

		<div class="select-options-item" v-on:click="onItemClick($event, option.value)" v-for="option in options" :key="option.value">

			{{ option.text }}

		</div>

	</div>
	
</div>

</template>

<script>

export default {

	props: ['placeholder', 'value', 'loading', 'options'],

	data: function() {

		return {
			is: {
				expanded: false
			}
		}

	},

	created: function() {

		window.addEventListener('click', this.onOutsideClick.bind(this))

	},

	beforeDestroy: function() {

		window.removeEventListener('click', this.onOutsideClick.bind(this))

	},

	computed: {

		valueText: function() {

			if (this.value) {

				return this.$_.findWhere(this.options, {
					value: this.value
				}).text

			} else {

				return this.placeholder

			}

		}

	},

	methods: {

		onItemClick: function(e, value) {

			e.stopPropagation()

			this.$emit('change', value)

			this.is.expanded = false

		},

		onOutsideClick: function() {

			this.is.expanded = false

		},

		onToggleExpanded: function(e) {

			e.stopPropagation()

			this.is.expanded = !this.is.expanded

		}

	}

}

</script>

<style scoped>

.select {
	border: 1px solid #BCE0FD;
	background-color: #fff;
	height: 48px;
}

.select.is-loading {
	background-image: url('data:image/gif;base64,R0lGODlhEAALAPQAAP///yaZ+9/v/dbr/ez2/Sub+yaZ+02r+5TM/He//MTi/UKm+2S2+5vP/HvA/Mfk/Uao+yma+2i4++n0/d3u/fX6/VWv++Hw/fP5/cDh/a7Y/NHp/fD3/QAAAAAAAAAAACH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAALAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQACwABACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQACwACACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQACwADACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAALAAQALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkEAAsABQAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAALAAYALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkEAAsABwAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA');
	background-position: calc(100% - 8px) 50%;
	background-repeat: no-repeat;
}

.select-value {
	line-height: 48px;
	padding: 0px 48px 0px 10px;
	font-size: 14px;
	color: #333;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: pointer;
}

.select-value .fa {
	color: #1D81D6;
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -7px;
}

.select.is-placeholder .select-value {
	color: #315f85;
}

.select-options {
	border: 1px solid #BCE0FD;
	position: absolute;
	left: -1px;
	top: 47px;
	background-color: #fff;
	width: calc(100% + 2px);
	z-index: 2;
}

.select-options-item {
	height: 48px;
	line-height: 20px;
	padding: 14px 10px;
	font-size: 14px;
	color: #333;
	cursor: pointer;
}

</style>