export default {

	computed: {

		time: function() {

			return this.$store.getters['time']

		},

		trueTime: function() {

			return this.$store.getters['time']

		}

	},

	methods: {

		countdown: function(deadline, asSeconds, trueTime) {

			trueTime = trueTime || false
			asSeconds = asSeconds || false

			var difference = deadline - ((trueTime) ? this.trueTime : this.time)

			if (difference < 0) difference = 0

			if (asSeconds) return difference

			var minutes = Math.floor(difference / 60)
			var seconds = difference - (minutes * 60)

			return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')

		}

	}

}