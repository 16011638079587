<template>

<app-screen title="Terms" :back="(loggedin) ? false : 'Login'" :textOnly="true" :menu="loggedin">

	<div v-html="texts.terms"></div>
	
</app-screen>

</template>

<script>

export default {

}

</script>

<style scoped>

</style>
