export default {

	computed: {

		texts: function() {

			return this.$store.getters['settings'].text

		},

		loggedin: function() {

			return this.$store.getters['session']

		}

	},

	methods: {

	}

}