<template>

<div class="icon">

	<i :class="{[classIcon]: true}"></i>

</div>

</template>

<script>

export default {

	props: ['name'],

	data: function() {

		return {
			reference: {
				backspace: 'fa fa-backspace',
				scan: 'fa fa-camera',
				back: 'fa fa-chevron-left',
				players: 'fa fa-users',
				user: 'fa fa-user',
				cancel: 'fa fa-remove',
				clock: 'fa fa-clock'
			}
		}

	},

	computed: {

		classIcon: function() {

			return this.reference[this.name]

		}

	}

}

</script>

<style scoped>

.code {
	padding: 64px;
}

</style>