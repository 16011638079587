<template>

<app-screen :loading="is.loading" :menu="true" title="Feed" toolbar="feed" :tabCurrent="tab" :tabs="tabs" v-on:tab="onTabChange">

	<div class="feed-wrapper">

		<com-item :item="item" v-for="(item, key) in feed" :key="key" />
	
	</div>
	
</app-screen>

</template>

<script>

import comItem from './feed/Item'

export default {

	components: {
		'com-item': comItem
	},

	data: function() {

		return {
			feed: [],
			tab: 'all',
			tabs: {
				all: 'Everyone',
				self: 'You'
			},
			is: {
				loading: true
			}
		}

	},

	created: function() {

		this.refresh()

	},

	methods: {

		onTabChange: function(value) {

			this.tab = value

			this.refresh()

		},

		refresh: function() {

			this.is.loading = true

			this.$api.request(this.$constants.api.feed, {
				context: this.tab
			}).then(function(response) {

				this.feed = response.feed

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.feed-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

</style>
