<template>

<div class="task" :class="{'is-completed': completed}" v-on:click="onClick">

	<div class="task-icon" v-if="!completed">

		<i class="fa fa-camera" v-if="task.type === $constants.task.photo"></i>
		<i class="fa fa-video" v-if="task.type === $constants.task.video"></i>
		<i class="fa fa-list-ol" v-if="task.type === $constants.task.multi"></i>

	</div>

	<div class="task-icon" v-if="completed">

		<i class="fa fa-check"></i>

	</div>
	
	<div class="task-name">

		{{ task.name }}

	</div>

	<div class="task-points">

		+{{ points }}

	</div>

</div>

</template>

<script>

export default {

	props: ['task'],

	computed: {

		points: function() {

			if (this.saved) {

				var saved = this.$store.getters['session/tasks'][this.task.id]

				if (this.task.type === this.$constants.task.multi && saved.status === this.$constants.task.incomplete) {

					return Math.floor(saved.points / 2)

				} else {

					return saved.points

				}

			} else {

				return this.task.points

			}

		},

		completed: function() {

			return this.$_.contains(this.$store.getters['session/tasks/completed'], this.task.id)

		},

		saved: function() {

			return this.$_.contains(this.$_.keys(this.$store.getters['session/tasks']), this.task.id)

		}

	},

	methods: {

		onClick: function() {

			this.$router.push({
				name: 'Task',
				query: {
					task: this.task.id
				}
			})

		}

	}

}

</script>

<style scoped>

.task {
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-bottom: 32px;
}

.task:last-child {
	margin-bottom: 0px;
}

.task-icon {
	flex-shrink: 0;
	width: 24px;
	line-height: 36px;
	color: #1D81D6;
	font-size: 16px;
}

.task-name {
	flex-grow: 1;
	line-height: 36px;
	padding: 0px 10px;
	color: #1D81D6;
	font-weight: bold;
	font-size: 16px;
}

.task.is-completed .task-name {
	color: #BCE0FD;
}

.task-points {
	flex-shrink: 0;
	width: 48px;
	height: 36px;
	line-height: 34px;
	text-align: center;
	border: 1px solid #1D81D6;
	border-radius: 8px;
	color: #1D81D6;
	font-size: 16px;
}

.task.is-completed .task-points {
	background-color: #1D81D6;
	color: #fff;
	font-weight: bold;
}

</style>
