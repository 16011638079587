<template>

<div class="pending">

	<com-task :task="task" v-for="task in tasks" :key="task.id" />

</div>

</template>

<script>

import comTask from './Task'

export default {

	components: {
		'com-task': comTask
	},

	computed: {
		
		completed: function() {

			return this.$store.getters['session/tasks/completed']

		},

		tasks: function() {

			return this.$_.filter(this.$store.getters['tasks'], function(task) {

				return !this.$_.contains(this.completed, task.id)

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>
