<template>

<app-screen :centralised="true">

	<div class="countdown-comed"></div>
	<div class="countdown-earthday"></div>

	<div class="countdown-text">

		<div class="countdown-text-prefix" v-html="texts.cd.prefix"></div>
		<div class="countdown-text-date">{{ date | formatDate(texts.cd.date) }}</div>
		<div class="countdown-text-text" v-html="texts.cd.text"></div>

	</div>
	
</app-screen>

</template>

<script>

export default {

	computed: {

		date: function() {

			return this.$store.getters['settings'].date.start

		}

	}

}

</script>

<style scoped>

.countdown-comed {
	width: calc(100% - 20px);
	height: 65px;
	background-image: url(~@/assets/logo-comed.png);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain;
}

.countdown-earthday {
	width: calc(100% - 20px);
	height: 100px;
	background-image: url(~@/assets/logo-earthday.png);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain;
	margin: 32px 0px;
}

.countdown-text-prefix {
	color: #1D81D6;
	text-transform: uppercase;
	font-size: 16px;
	margin-bottom: 10px;
	text-align: center;
}

.countdown-text-date {
	color: #1D81D6;
	font-size: 34px;
	text-align: center;
	font-weight: bold;
}

.countdown-text-text {
	font-size: 16px;
	line-height: 24px;
	margin-top: 32px;
	text-align: center;
	color: #1D81D6;
}

</style>
