<template>

<app-screen :blue="true" :centralised="true">

	<div class="locked" v-html="texts.lo.text"></div>
	
</app-screen>

</template>

<script>

export default {

}

</script>

<style scoped>

.locked {
	text-align: center;
	color: #fff;
	font-size: 32px;
	line-height: 40px;
	font-weight: bold;
}

.locked >>> p {
	margin-bottom: 16px;
}

.locked >>> p:last-child {
	margin-bottom: 0px;
}

</style>
