<template>

<app-screen :title="texts.ob.rules.title" :textOnly="true" :menu="true">

	<div v-html="texts.ob.rules.text"></div>
	
</app-screen>

</template>

<script>

export default {

}

</script>

<style scoped>

</style>
